import React, { Component } from 'react';
import { audioContextClass } from '../constants/util.js';
import MIDIInterface from '../lib/MIDIInterface';
import AudioKeys from 'audiokeys';
import { Voice } from './Voice';
import './Synth.scss';

interface SynthState {
  keyboard: any,
  context: AudioContext,
  compressor: DynamicsCompressorNode|null
  midiInterface: any
}

interface SynthProps {

}

interface SynthInterface {
  voices: Voice[],
  setVoiceRef:Function
}

class Synth extends Component<SynthProps, SynthState> implements SynthInterface {
  voices:Voice[] = new Array() as Voice[];

  constructor() {
    super();
    
    this.state = {
      keyboard: new AudioKeys({
        rootNote: 64
      }),
      context: new audioContextClass(),
      compressor: null,
      midiInterface: (MIDIInterface) ? MIDIInterface.handler(this.handleMidiAccess) : false
    };

    this.setVoiceRef = el => {
      this.voices.push(el);
    }    
  }

  setVoiceRef(el:any) {}

  prepareAudio() {
    const { context } = this.state;
    const compressor = context.createDynamicsCompressor();
    compressor.threshold.value = -20;
    compressor.knee.value = 20;
    compressor.ratio.value = 12;
    compressor.attack.value = 0;
    compressor.release.value = 0.25;
    compressor.connect(context.destination);
    this.setState({compressor: compressor});
  }

  componentDidMount() {
    const { keyboard } = this.state;
    keyboard.up( (note:{note:number, velocity:number}) => this.voiceOff(note.note, note.velocity));
    keyboard.down( (note:{note:number, velocity:number}) => this.voiceOn(note.note, note.velocity));
    this.prepareAudio();
  }

  voiceOn(note:number, velocity:number) {
    this.voices.forEach( voice => voice.noteDown(note,velocity));
  }

  voiceOff(note:number, velocity:number) {
    this.voices.forEach( voice => voice.noteUp(note));
  }

  handleMidiAccess(message:any) {
    const { context } = this.state;
    if(context.state === 'suspended') return;
    const [key, note, velocity] = message.data;
    if(note >= 0 && note <= 127) {
      switch(key) {
      case 128:
        this.voiceOn(note, velocity);
        break;
      case 144:
        this.voiceOff(note, velocity);
        break;
      default:
        // noop
      }  
    }
  }

  render() {
    const { context, compressor } = this.state;
    
    return (
      <div className="synth">
        <div className="knobset">
          <Voice title="Osc1" ref={ this.setVoiceRef } context={ context } output={ compressor } />
          <Voice title="Osc2" ref={ this.setVoiceRef } context={ context } output={ compressor } />
        </div>
        <div>
          <h2>Playing:</h2>
          <p>If you are using Chrome, plug in a midi keyboard; you may need to reload this page.</p>
          <p>Or, play using the computer keyboard:</p>
          <p>On a QWERTY keyboard:</p>
          <ul>
            <li>The letter keys on the <code>asdf</code> row are the white piano keys, starting with Middle C</li>
            <li>The letter keys on the <code>qwerty</code> row are the black piano keys</li>
            <li><code>z</code> will lower the entire keyboard one octave</li>
            <li><code>x</code> will raise the entire keyboard one octave</li>
          </ul>
        </div>
      </div>
    );
  }
}

export default Synth;