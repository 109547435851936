class MIDIInterface {
  static createMIDIInterface(messageHandler) {
    if(navigator.requestMIDIAccess) {
      return new MIDIInterface(messageHandler);
    } else {
      console.warn('The Web MIDI API is not yet supported on any browser except Chrome.');
      return false;
    }
  }

  handler(fn) {
    this._handler = (typeof fn === 'function') ? fn : (message) => {
      console.warn('Using default midi message handler');
      console.log(message.data);
    };
  }

  handleMidiAccess(midiAccess) {
    const inputs = midiAccess.inputs.values();
    for(let input = inputs.next(); input && !input.done; input = inputs.next()) {
      input.value.onmidimessage = this._handler;
    }
  }

  constructor() {
    this.handleMidiAccess = this.handleMidiAccess.bind(this);
    this.handler();
    this._midiInterface = navigator.requestMIDIAccess()
      .then(this.handleMidiAccess)
      .catch((e) => {
        console.warn(e);
      });
  }
}

export default (navigator.requestMIDIAccess) ? MIDIInterface.createMIDIInterface() : false;

