// At least pretend to support webkit audiocontext
export const audioContextClass = window.AudioContext || window.webkitAudioContext;

// Oscillator shape styles. The index of the position in the array
// can be used for knob values
export const oscillatorShapes = [
  'sine',
  'square',
  'sawtooth',
  'triangle'
];