import React, { Component } from 'react';
import './App.css';
import Synth from './components/Synth';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Yikes404 from './components/Yikes404';

class App extends Component {

  render() {
    return (
      <BrowserRouter>
        <div className="App">
          <Switch>
            <Route path="/" exact component={Synth} />>
            <Route component={Yikes404} status={404}/>
          </Switch>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
