import React, { Component } from 'react';

class Yikes404 extends Component {
  render() {
    return (
      <div className="App">
        <h1>Yikes, 404</h1>
        <p>Even though this claims to be a 404 page, it is sent with a 200 error which is really annoying</p>
      </div>
    );
  }
}

export default Yikes404;