import React, { Component } from 'react';
import { Knob } from 'react-rotary-knob';
import { oscillatorShapes } from '../constants/util.js';

interface VoiceInterface {
  playing: NoteInterface[]|null,
}

export interface NoteInterface {
  oscillator: OscillatorNode,
  gainNode: GainNode
}

interface VoiceProps {
  title: string,
  context: AudioContext,
  output: any
}

interface VoiceState {
  shape: number,
  offset: number
}

export class Voice extends Component<VoiceProps, VoiceState> implements VoiceInterface {
  playing = [] as NoteInterface[];

  constructor(props:VoiceProps) {
    super(props);
    this.state = {
      shape: 0,
      offset: 0,
    };
  
    // This is not managed through state because state updates
    // depend on animation frames and we need it to be updated
    // much more quickly than that; the microseconds count.
    this.playing = [] as NoteInterface[];
  }

  prepareNote(pitch: number, velocity: number) {
    const { offset, shape } = this.state;
    const { context, output } = this.props;
    const oscillator = context.createOscillator();
    oscillator.type = oscillatorShapes[shape] as OscillatorType;
    oscillator.detune.value = ((pitch - 69) + offset) * 100;
    
    const gainNode = context.createGain();
    gainNode.gain.value = .00001;
    oscillator.connect(gainNode);
    gainNode.connect(output);
    oscillator.start();
    
    return {
      oscillator: oscillator,
      gainNode: gainNode
    };
  }

  noteDown = (note:number, velocity:number) => {
    if(!this.playing[note]) {
      this.playing[note] = this.prepareNote(note, velocity);
    }
    const { gainNode } = this.playing[note];
    const { context } = this.props;
    gainNode.gain.exponentialRampToValueAtTime(1,context.currentTime + 0.03);
  }

  noteUp = (note:number) => {
    if(this.playing[note]) {
      const { oscillator, gainNode } = this.playing[note];
      const { context } = this.props;
      gainNode.gain.exponentialRampToValueAtTime(.0001,context.currentTime + 0.03);
      oscillator.stop(context.currentTime + .05);
      this.playing.splice(note,1);
    }
  }  

  handleShapeChange = (val:number) => {
    if (Math.round(val) === this.state.shape || val > 3.5) {
      return; 
    } else {
      this.setState({ shape: Math.floor(val)});
    }
  }

  handleOffsetChange = (val:number) => {
    if (Math.round(val) === this.state.offset || val > 12.5) {
      return; 
    } else {
      this.setState({ offset: Math.floor(val)});
    }
  }

  render() {
    const { title } = this.props;
    const { shape, offset } = this.state;

    return (
      <div>
        <h1>{ title }</h1>
        <label>{ oscillatorShapes[shape] }
          <Knob
            onChange={this.handleShapeChange}
            min={0}
            max={4}
            step={1}
            value={shape}
            preciseMode={true}
            unlockDistance={20}
          />
        </label>
        <label>{ offset }st
          <Knob
            onChange={this.handleOffsetChange}
            min={-12}
            max={13}
            step={1}
            value={offset}
            preciseMode={true}
            unlockDistance={20}
          />
        </label>
      </div>
    );
  }
}
